import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { PlayFabClient, PlayFab } from 'playfab-sdk'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import {playFabSettings} from '../../config'
import './Scss/Auth.scss'
function Login() {
    const history = useHistory();
    const [input, setInput] = useState({
        email: "",
        password: ""
    })
    const onChangeInput = (event) => {
        setInput({ ...input, [event.target.name]: event.target.value })
    }
    const onClickLogin = () => {
        console.log(input)
        PlayFab.settings.titleId = playFabSettings.TitleId;
        var loginRequest = {
            TitleId: playFabSettings.TitleId,
            Email: `${input.email}`,
            Password: `${input.password}`,
        }
        console.log("registrationRequest", loginRequest);
        PlayFabClient.LoginWithEmailAddress(loginRequest, LoginCallback);
    }
    const onClickGuest = () => {
        const CustomID = uuidv4();
        localStorage.setItem('CustomID', CustomID);
        PlayFab.settings.titleId = playFabSettings.TitleId;
        var loginRequest = {
            TitleId: playFabSettings.TitleId,
            CustomId: CustomID,
            CreateAccount: true
        };
        console.log("registrationRequest", loginRequest);
        PlayFabClient.LoginWithCustomID(loginRequest, LoginCallback);
    }
    function LoginCallback(error, result) {
        if (result !== null) {
            toast.success("Logged In Successfully!",{ position: toast.POSITION.BOTTOM_RIGHT });
            console.log(result)
            localStorage.setItem('PlayFabId', result.data.PlayFabId);
            localStorage.setItem('EntityToken', result.data.EntityToken.EntityToken);
            history.push('/')
            // console.log("Congratulations, you made your first successful API call!");
        } else if (error !== null) {
            // console.l og("Something went wrong with your first API call.");
            // console.log("Here's some debug information:");
            toast.error(CompileErrorReport(error))
            console.log(CompileErrorReport(error));
        }
    }

    // This is a utility function we haven't put into the core SDK yet.  Feel free to use it.
    function CompileErrorReport(error) {
        if (error == null)
            return "";
        var fullErrors = error.errorMessage;
        for (var paramName in error.errorDetails)
            for (var msgIdx in error.errorDetails[paramName])
                fullErrors += "\n" + paramName + ": " + error.errorDetails[paramName][msgIdx];
        return fullErrors;
    }
    return (
        <div id="auth_page">
            <div className="auth_container">
                <h2 className="mb-4">MiniMall Online</h2>
                <div className="auth_form">
                    <Row className="m-0">
                        <Col xs={6} className="login_container p-2" onClick={() => { history.push('/login') }}>
                            <Button className="auth_btn">Login</Button>
                        </Col>
                        <Col xs={6} className="deactive_tab p-2" onClick={() => { history.push('/registration') }}>
                            <Button className="auth_btn">SignUp</Button>
                        </Col>
                    </Row>
                    <div className="p-3 auth_body">
                        <input type="email" name="email" placeholder="Email" onChange={onChangeInput} />
                        <input type="password" name="password" placeholder="Password" onChange={onChangeInput} />
                        <Button className="Login_btn" onClick={onClickLogin}>LOGIN</Button>
                        <h6 className="text-center forget-pass"><u>Forget Password ?</u></h6>
                        <Button className="guest_btn" onClick={onClickGuest}>COUNTINUE AS GUEST</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login
