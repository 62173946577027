import { SEND_MESSAGE} from '../Redux-store/actionType';
import { toast } from 'react-toastify';
const INIT_STATE = {
    messageList: []
}

const Messages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEND_MESSAGE:
            let tempList = [...state.messageList];
            tempList.push(action.payload);
            return { ...state, messageList: tempList };
        default:
            return state;
    }
}
export default Messages;