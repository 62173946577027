import {ASSIGN_ITEM_TO_STORE,REMOVE_ITEM_FROM_STORE,REDUCE_QUANTITY} from '../Redux-store/actionType'
export const addItemToStore = (item) =>{
    return (dispatch) =>{
        dispatch({ type: ASSIGN_ITEM_TO_STORE, payload:item });
    }
}

export const removeItemFromCart = (item) =>{
    return (dispatch) =>{
        dispatch({ type: REMOVE_ITEM_FROM_STORE, payload:item });
    }
}

export const reduceQuantity = (item) =>{
    return (dispatch) =>{
        dispatch({ type: REDUCE_QUANTITY, payload:item });
    }
}
