import { React, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
//import Route
import Login from "./Container/Authentication/Login";
import Registration from "./Container/Authentication/Registration";
import Home from './Container/Pages/Home'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="login Page" render={() => (<Login />)} />
          <Route exact path="/registration" name="Registration page" render={() => (<Registration />)} />
          <Route path="/" name="Private Route" render={() => (<PrivateRoute />)} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}
function PrivateRoute() {
  const isUserLoggedIn = () => {
    const EntityToken = localStorage.getItem('EntityToken')
    if (EntityToken) {
      return true;
    }
    else {
      return false;
    }
  }
  return (
    <>
      <Switch>
        {isUserLoggedIn() && <Route exact path="/" name="Home page" render={() => (<Home />)} />}
        <Redirect from="/" to="/login" />
      </Switch>
    </>
  )
}
export default App;
