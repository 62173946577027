import React,{useState} from 'react'
import Header from '../../Components/Layout/Header'
import PlayCanvas from '../../Components/CommonComponent/PlayCanvas'
function Home() {
    const [isIframeLoded,setIsIframeLoaded] = useState(false);
    return (
        <div>
            <Header isIframeLoded={isIframeLoded}/>
            <PlayCanvas setIsIframeLoaded={(status)=>{setIsIframeLoaded(status)}}/>
        </div>
    )
}

export default Home
