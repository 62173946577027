import {createStore, applyMiddleware, compose} from  'redux'
import thunkMiddleware from "redux-thunk";

import Reducers from "../Redux-reducer";

const store = createStore(Reducers, compose(
    applyMiddleware(thunkMiddleware),
    //For working redux dev tools in chrome
    window.devToolsExtension ? window.devToolsExtension() : (f) => {
        return f;
    }
));

export default store