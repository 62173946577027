import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import PurchaseItem from "../PopUp/PurchaseItem";
import {addItemToStore} from '../../Redux-actions/Storage'
import { useDispatch, useSelector } from 'react-redux'
function PlayCanvas(props) {
    const [showPurchaseItem,setShowPurchaseItem] = useState(false);
    const [purchaseId,setPurchaseId] = useState(false);
    const [isIframeLoded,setIsIframeLoaded] = useState(false);

    let origin_url = 'https://playcanv.as';
    // origin_url = 'minimallonline.com';
    var mall_url = 'https://playcanv.as/e/p/wiARTIf3/';
    // mall_url = 'https://minimallonline.com/mall/';

    const dispatch = useDispatch();
    useEffect(() => {
        window.addEventListener("message", function (event) {
            if (!event.origin.includes(origin_url)) {
                return;
            }
            if (event.data.messageType === "itemData") {
                setPurchaseId(event.data.message)
                setShowPurchaseItem(true);
            }
            if (event.data.messageType === "initGame") {
                if(props.setIsIframeLoaded){
                    props.setIsIframeLoaded(true);
                }
            }
        });
    });
    return (
        <div id="playcanvas-wrap">
            <iframe id="hello" src="https://playcanv.as/e/p/wiARTIf3/" width="100%" height="100%" />
            <PurchaseItem
                show={showPurchaseItem}
                onHide={()=>{setShowPurchaseItem(false)}}
                purchaseId={purchaseId}
                onPurchase={(product)=>{
                    dispatch(addItemToStore(product));
                    setShowPurchaseItem(false)
                }}
            />
        </div>
    )
}

export default PlayCanvas
