import React, { useState, useEffect } from 'react'
import { Navbar, Nav, InputGroup, FormControl, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import ScrollableFeed from 'react-scrollable-feed'
import { removeItemFromCart, reduceQuantity, addItemToStore } from '../../Redux-actions/Storage'
import { sendMessage } from '../../Redux-actions/Messages'
import "./Layout.scss"
import Menu from '../../Assets/Icons/menu.png'
import ShoppingCart from '../../Assets/Icons/shoppingCart.png'
import Message from '../../Assets/Icons/message.png'
import User from '../../Assets/Icons/user.png'
function Header(props) {
    const [showMessageBox, setShowMessageBox] = useState(true);
    const [showCartBox, setShowCartBox] = useState(false);
    const [chatMessage, setChatMessage] = useState('');
    const [showProfileMenu,setShowProfileMenu] = useState(false);
    const cartList = useSelector(state => state.Storage.productList);
    const messageList = useSelector(state => state.Messages.messageList)
    const [Total , setTotal]= useState(0)
    const dispatch = useDispatch()
    const onSendMessage = () => {
        if (props.isIframeLoded) {
            var iframe = document.getElementById('hello');
            iframe.contentWindow.postMessage({ messageType: 'chatMessage', message: chatMessage }, '*');
            dispatch(sendMessage(chatMessage));
            setChatMessage('');
        }
        else {
            alert('Playcanvas loading...')
        }
    }

    useEffect(()=>{
        let totalprice = 0
        cartList.map((item => totalprice = item.id[0].price * item.quantity + totalprice ))
       setTotal(totalprice)
    },[cartList])
    return (
        <>
            <Navbar style={{ backgroundColor: "whitesmoke" }}>
                <Navbar.Brand><img src={Menu} height="40px" width="25px" /></Navbar.Brand>
                <div className="mx-auto"><h4 className="m-0" style={{ fontWeight: "700" }}>MUIO Shopping Center</h4></div>
                <Nav className="mr-0">
                    <Nav.Link className="position-relative" onClick={() => { setShowMessageBox(!showMessageBox) }}><img src={Message} height="30px" width="40px" />
                        {!showMessageBox && <i className="position-absolute" style={{ right: -5, top: -10 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="red" class="bi bi-dot" viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            </svg></i>}
                    </Nav.Link>
                    <Nav.Link className="position-relative" onClick={() => {setShowProfileMenu(false);setShowCartBox(!showCartBox)}}><img src={ShoppingCart} height="30px" width="40px" />
                        {!showCartBox && cartList.length>0 && <i className="position-absolute" style={{ right: -5, top: -10 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="red" class="bi bi-dot" viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            </svg></i>}
                    </Nav.Link>
                    <Nav.Link><img src={User} height="30px" width="35px" onClick={() => { setShowCartBox(false);setShowProfileMenu(!showProfileMenu) }}/></Nav.Link>
                </Nav>
            </Navbar>
            {showMessageBox && <div className="p-4 position-absolute on-top" style={{ bottom: 0 }}>
                <div className="p-3 mb-1" style={{ maxHeight: "200px", maxWidth: "300px", backgroundColor: "#fff", opacity: "0.5" }}>
                    <ScrollableFeed>
                        {messageList && messageList.map((message, idx) => {
                            return <p key={idx} className="mb-1">{message}</p>
                        })}
                        <p></p>
                    </ScrollableFeed>
                </div>
                <InputGroup>
                    <FormControl
                        type="text"
                        placeholder="Type here..."
                        onChange={(e) => { setChatMessage(e.target.value) }}
                        onKeyPress={(e)=>e.key==="Enter"?onSendMessage():""}
                        value={chatMessage}
                    />
                    <InputGroup.Append>
                        <Button className="border-0" style={{ backgroundColor: "rgb(216, 216, 216)" }} onClick={onSendMessage}>
                            <i><svg style={{ marginTop: "-3px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-telegram" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                            </svg></i></Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>}
            {showCartBox &&
                <div className="p-4 position-absolute mt-1 on-top" style={{ right: 0, backgroundColor: "whitesmoke" }}>
                    {cartList && cartList.map((cartdata, index) => {
                        return <div className="d-flex p-2 position-relative" key={index}>
                            <i style={{ position: "absolute", top: "1px", right: "1px" }} onClick={() => { dispatch(removeItemFromCart(cartdata)) }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="red"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </i>
                            <img src={`/Store Images/${cartdata.id[0].purchaseId}.png`}
                                style={{ width: "150px", height: "100px", borderRadius: "10px" }}
                            />
                            <div className="p-1" style={{ width: '250px' }}>
                                <h6 className="mt-1 ml-1"><b>{cartdata.id[0].name}{cartdata.id[0].purchaseId}</b></h6>
                                <p className="m-1">Price : ${cartdata.id[0].price}</p>
                                <div className="d-flex">
                                    <button className="p-1 px-2 border-0" onClick={() => { dispatch(reduceQuantity(cartdata.id)) }}> - </button>
                                    <div className="p-1 px-2">{cartdata.quantity}</div>
                                    <button className="p-1 px-2 border-0" onClick={() => { dispatch(addItemToStore(cartdata.id)) }}> + </button>
                                </div>
                            </div>
                        </div>
                    })
                    }
                    {
                        !cartList.length > 0 && <h6 className="mt-1 ml-1 text-center"><b>No Item Found</b></h6>
                    }
                    <hr />
                    <h6 className="mt-1 ml-1"><b>{`Total : $${Total}`}</b></h6>
                    {cartList.length > 0 ? <button className="p-2 w-100 border-0 mt-4 mb-4" style={{ background: "#3099fb" }}><b>PURCHASE ITEM</b></button> :
                        <button onClick={()=>{setShowCartBox(false)}} className="p-2 w-100 border-0 mt-4 mb-4" style={{ background: "#3099fb" }}><b>Continue Shopping</b></button>}
                    {/* <button className="p-2 w-100 border-0 mt-4 mb-4" style={{ background: "#3099fb" }}><b>{"PURCHASE ITEM" : "Continue Shopping"}</b></button> */}
                </div>
            }
            {
                showProfileMenu && 
                <div className="p-2 position-absolute mt-1 on-top" style={{ right: 0, backgroundColor: "whitesmoke" }}>
                    <Button className="px-4" variant="secondary" onClick={()=>{localStorage.clear()}}>Logout</Button>
                </div>
            }
        </>
    )
}

export default Header
