import { ASSIGN_ITEM_TO_STORE,REMOVE_ITEM_FROM_STORE,REDUCE_QUANTITY } from '../Redux-store/actionType';
import { toast } from 'react-toastify';
import Products from '../Assets/Data/products.json'
const INIT_STATE = {
    Products,
    productList: []
}

const Storage = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ASSIGN_ITEM_TO_STORE:
            let tempList = [...state.productList];  
            if(tempList.some((item)=>item.id===action.payload)){
                tempList.map((item,index)=>{
                    if(item.id===action.payload){
                        tempList[index]={...item,quantity:tempList[index].quantity+1}
                    }
                })
            }
            else{
                tempList.push({id:action.payload,quantity:1});
                toast.success("Item Added To The Cart",{ position: toast.POSITION.BOTTOM_RIGHT })
            }
            return { ...state, productList: tempList };

        case REMOVE_ITEM_FROM_STORE:
            let tempRemoveList = [...state.productList];
            tempRemoveList = tempRemoveList.filter((item) =>item.id!=action.payload.id)
            return { ...state, productList: tempRemoveList };
        
        case REDUCE_QUANTITY:
            let tempReduce = [...state.productList];
            tempReduce.map((item,index)=>{
                if(item.id===action.payload){
                    tempReduce[index]={...item,quantity:tempReduce[index].quantity-1}
                }
            })
            return { ...state, productList: tempReduce };
        default:
            return state;
    }
}
export default Storage;