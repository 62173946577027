import React,{useState} from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
//import Axios from 'axios'
import {PlayFabClient,PlayFab} from 'playfab-sdk'
import { toast } from "react-toastify";
import {playFabSettings} from '../../config'
import './Scss/Auth.scss'
function Registration() {
    const history = useHistory()
    const [input,setInput] = useState({
        firstname:"",
        lastname:"",
        email:"",
        password:"",
        cpassword:""
    })
    const onChangeInput = (event) =>{
        setInput({...input,[event.target.name]:event.target.value})
    }
    function registerCallback(error, result) {
        if (result !== null) {
            toast.success("Registration Successful!")
            history.push('/login')
            // console.log("Congratulations, you made your first successful API call!");
        } else if (error !== null) {
            // console.log("Something went wrong with your first API call.");
            // console.log("Here's some debug information:");
            toast.error(CompileErrorReport(error));
            console.log(CompileErrorReport(error));
        }
    }
    
    // This is a utility function we haven't put into the core SDK yet.  Feel free to use it.
    function CompileErrorReport(error) {
        if (error == null)
            return "";
        var fullErrors = error.errorMessage;
        for (var paramName in error.errorDetails)
            for (var msgIdx in error.errorDetails[paramName])
                fullErrors += "\n" + paramName + ": " + error.errorDetails[paramName][msgIdx];
        return fullErrors;
    }

    const onClickRegister = () =>{
        let regPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,12}$/;
        if(input.password!==input.cpassword){
            toast.error("Passwords do not match!")
        }
        else if(input.firstname === "" || input.lastname==="" || input.email==="" || input.password===""){
            toast.error("Please complete all mandatory fields")
        }
        else if(input.password && !regPass.test(input.password)){
            debugger
            toast.error("Passwords require 1 uppercase letter, 1 lowercase letter, 1 number, and at least 6 characters.")
        }
        else{
            PlayFab.settings.titleId=playFabSettings.TitleId;
            const username = input.email.split('@')[0];
            var registrationRequest = {
                    TitleId:playFabSettings.TitleId,
                    DisplayName:`${input.firstname}`,
                    email:`${input.email}`,
                    password:`${input.password}`,
                    Username:`${username}`,
                    firstname:`${input.firstname}`,
                    lastnames:`${input.lastname}`,
                }
            console.log("registrationRequest",registrationRequest);
            PlayFabClient.RegisterPlayFabUser(registrationRequest,registerCallback);
        }
    }
    return (
        <div id="auth_page">
            <div className="auth_container">
                <h2 className="mb-4">MiniMall Online</h2>
                <div className="auth_form">
                    <Row className="m-0">
                        <Col xs={6} className="deactive_tab p-2 py-3" onClick={() => { history.push('/login') }}>
                            <div className="auth_btn">Login</div>
                        </Col>
                        <Col xs={6} className="p-2 py-3" onClick={() => { history.push('/registration') }}>
                            <div className="auth_btn">SignUp</div>
                        </Col>
                    </Row>
                    <div className="p-3 auth_body">
                        <label><b>firstname</b>
                            <input type="text" name="firstname" placeholder="*" onChange={onChangeInput}/>
                        </label>
                        <label><b>lastname</b>
                            <input type="text" name="lastname" placeholder="*" onChange={onChangeInput}/>
                        </label>
                        <label><b>Email Address</b>
                            <input type="email" name="email" placeholder="*" onChange={onChangeInput}/>
                        </label>
                        <label><b>Password</b>
                            <input type="password" name="password" placeholder="*" onChange={onChangeInput}/>
                        </label>
                        <label><b>Confirm Password</b>
                            <input type="password" name="cpassword" placeholder="*" onChange={onChangeInput}/>
                        </label>
                        <Button className="Login_btn" onClick={onClickRegister}>Register</Button>            
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Registration
