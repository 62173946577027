import React, { useState, useEffect } from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap'
import { addItemToStore } from '../../Redux-actions/Storage'
import './scss/PurchaseItem.scss'
import { useSelector } from 'react-redux'

function PurchaseItem(props) {
    const Products = useSelector(state => state.Storage.Products )
    const [pro , setPro] = useState([])
    useEffect(()=>{
        console.log( props.purchaseId)
        let product = Products.filter(item => item.purchaseId == props.purchaseId)
        setPro(product)       
    },[props.purchaseId])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body
                    id="PurchaseItem" className="p-4 position-relative">
                    <i style={{ position: "absolute", top: "1px", right: "1px" }} onClick={props.onHide}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="red"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                        >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </i>
                    <Row>
                        <Col xs={12} lg={4} sm={5}>
                            <img src={`/Store Images/${props.purchaseId}.png`}
                                style={{ width: "100%", height: "90%", borderRadius: "10px" }}
                            />
                            
                        </Col>
                   {pro.length > 0 ? (   <Col>
                      <h6 className="mt-1 ml-1"><b>{pro[0].name}</b></h6>
                            <p className="mt-1 ml-1">Price : ${pro[0].price}</p>
                            <ul className="pl-4">
                                {
                                   pro[0].discription.map((deteil, index)=>{  return  <li key={index}><span>{deteil}</span></li> } )
                                }
                            </ul>
                            <button className="p-2 w-100 border-0 mt-4 mb-4" style={{ background: "#3099fb" }} onClick={()=>props.onPurchase(pro)}><b>PURCHASE ITEM</b></button>
                      </Col>) : null}
                        
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PurchaseItem
